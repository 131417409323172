@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: "Poppins", serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
button {
  @apply border border-black px-4 py-2 w-[90vw] max-w-[200px] rounded bg-[#e4c53c] hover:bg-[#ddbe34] text-left;
}
.selected {
  @apply bg-[#242423] hover:bg-[#242423] text-white;
}
input {
  @apply border-b border-black  py-2 w-[100%] max-w-[500px]  bg-transparent placeholder:text-gray-500;
}
input::placeholder {
  color: rgb(10 15 18 / 50%);
}
input:focus {
  @apply border-b border-black outline-none;
}

p {
  @apply text-lg;
}

.dropdown {
  @apply flex items-center justify-between px-4 py-2 bg-transparent border-b-2 border-black outline-none;
}
.question-card {
  @apply flex flex-col gap-2 w-[100%] max-w-[500px] relative;
}

.questions {
  @apply flex flex-col gap-2;
}
.multiple-choice {
  @apply flex flex-wrap items-stretch h-full gap-2; /* Ensure the container is a flex column and stretches */
}

.multiple-choice button {
  @apply flex-1 min-w-[200px]; /* Make the buttons grow and shrink equally */
}

.navigation-buttons {
  @apply absolute bottom-[5%] right-[5%] flex items-center justify-center gap-2;
}
.navigation-buttons button {
  @apply w-10 h-10 p-0 text-white bg-black hover:bg-gray-800;
}
